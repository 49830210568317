<template>
  <app-header/>
  <main class="main-container container-fluid d-flex pt-20 px-0 position-relative">
    <app-sidebar />
    <article class="main-content mt-10" style="margin-top: 0.2rem !important;">
    <Suspense>
      <template #default>
        <AppPlayGames :gid='gid'/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  </article>
</main>
</template>

<script>
// import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
import AppPlayGames from '../../components/Pages/AppPlayGames.vue'
import AppSidebar from '../../components/layouts/AppSidebar.vue'
export default {
  components: { AppHeader, AppLoading, Suspense, AppPlayGames, AppSidebar },
  props: {
    gid: String
  },
   name: 'PlayGames'
}
</script>

<style>

</style>