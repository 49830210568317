<template>
    <!-- <section class="game-section pb-120" > -->
        <!-- <div class="container" style="padding-left: 0;padding-right: 0"> -->
            <!-- <iframe style="max-height: calc(100vh);width: <?php echo $jsonGame->data->width; ?>;height: <?php echo $jsonGame->data->height; ?>;" :src="game.url" width="100%" height="900" frameborder="0" scrolling="no"></iframe> -->
            <template v-for="game in gamesDetails" :key="game.id">
              <!-- <iframe :src="game.url"  frameborder="0" scrolling="no"></iframe> -->
              <iframe title="Game Container" id="iframe-lazyload-src" allow="autoplay; fullscreen; picture-in-picture; cross-origin-isolated" :src="game.url" width="100%" height="100%" scrolling="no" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
            </template>
            
             <!-- <iframe title="Game Container" id="iframe-lazyload-src" allow="autoplay; fullscreen; picture-in-picture; cross-origin-isolated" :src="url_data" width="100%" height="100%" scrolling="yes" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe> -->
        <!-- </div> -->
    <!-- </section> -->
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
// import { watch } from '@vue/runtime-core';
// import { HTTPSPA } from '@/Api/http-Spay';
export default {
    name: 'AppPlayGames',
    props: {
        gid: String
    },
    // data() {
    //   return {
    //     url_data: null
    //   };
    // },
    mounted() {
      const s = document.createElement('script');
        s.type = 'text/javascript';
        s. src = '/include/js/main.js';
        document.body.appendChild(s);

      // this.url_data='https://tagpres.com/GengGamesPage/index.php?id='+this.$route.params.gid;
    },
    async setup(props) {
      const gamesDetails = ref([]);

      try {
        await HTTP.get(`GamesDetailsById.php?id=`+props.gid).then((res) => {
          gamesDetails.value = res.data.GamesDetails; 
        });
      } catch (err) {
        console.log(err);
      }

      return {
        gamesDetails
      };
    }
}
</script>

<style>
/* iframe{width: 100vw; height: 100vh;}body{margin:0} */
iframe{
  width: 100%;
  height: 90vh;
  overflow:hidden;
}
@media(max-width:1024px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:834px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:820px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:810px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:786px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:768px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:428px){
  iframe{
    max-height: 90vh;
  }
}
@media(max-width:414px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:412px){
  iframe{
    max-height: 89vh;
  }
}

@media(max-width:384px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:375px){
  iframe{
    max-height: 89vh;
  }
}
@media(max-width:360px){
  iframe{
    max-height: 89vh;
  }
}
</style>